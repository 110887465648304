/* libre-baskerville-regular - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
       url($baseurl + '/assets/fonts/libre-baskerville-v5-latin-regular.woff2') format('woff2');
}

/* libre-baskerville-italic - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 400;
  src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'),
       url($baseurl + '/assets/fonts/libre-baskerville-v5-latin-italic.woff2') format('woff2');
}

/* libre-baskerville-700 - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'),
       url($baseurl + '/assets/fonts/libre-baskerville-v5-latin-700.woff2') format('woff2');
}


/* oswald-variable_wght 200-700 */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  src: local('Oswald ExtraLight'), local('Oswald-ExtraLight'),
       url($baseurl + '/assets/fonts/oswald-ExtraLight.woff2') format('woff2');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'), 
       url($baseurl + '/assets/fonts/oswald-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), 
       url($baseurl + '/assets/fonts/oswald-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald Medium'), local('Oswald-Medium'), 
       url($baseurl + '/assets/fonts/oswald-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: local('Oswald SemiBold'), local('Oswald-SemiBold'), 
       url($baseurl + '/assets/fonts/oswald-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald Bold'), local('Oswald-Bold'), 
       url($baseurl + '/assets/fonts/oswald-Bold.woff2') format('woff2');
}



/* merienda-regular - latin-ext_latin */
@font-face {
   font-family: 'Merienda';
   font-style: normal;
   font-weight: 400;
   src: local('Merienda'), local('Merienda-Regular'),
        url($baseurl + '/assets/fonts/merienda-v14-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url($baseurl + '/assets/fonts/merienda-v14-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}





/* Material Icons */
@font-face {
   font-family: 'Material Icons';
   font-style: normal;
   font-weight: 400;
   src: url($baseurl + '/assets/fonts/material-icons.woff2') format('woff2');
}

.material-icons {
   font-family: 'Material Icons';
   font-weight: normal;
   font-style: normal;
   font-size: 24px;
   line-height: 1;
   letter-spacing: normal;
   text-transform: none;
   display: inline-block;
   white-space: nowrap;
   word-wrap: normal;
   direction: ltr;
   -moz-font-feature-settings: 'liga';
   -moz-osx-font-smoothing: grayscale;
}
