.navigate-back-to-top:before {
   display: inline-block;
   content: "↥";
   color: black;
   background: $light-tint;
   font-size: 1.2em;
   padding: 10px;
   width: 1em;
   height: 1em;
   border-radius: 2px;
   line-height: 1em;

   position: fixed;
   right: 10px;
   top:10px;
   text-align: center;
}

header {
   position: relative;
   z-index: 1000;
   width: 100%;
   background-color: $light-header-background;

   padding: 10px 20px;
   font-family: $sans-serif;
   font-weight: 300;
   font-style: normal;
   font-size: 18px;

   #hamburgerCheckbox {
      display: none;
   }

   nav {
      display: grid;
      width: 100%;
      max-width: $max-content-width;
      margin: 0 auto;
      min-height: calc(1.2em + 30px);

      grid-template-columns: auto auto;
      grid-template-rows: 100%;

      .logo {
         font-family: $fancy;
         justify-self: start;

         a {
            color: $light-tint;
         }
      }

      div {
         display: inline-block;
         justify-self: end;
         align-self: center;
      }

      ul {
         justify-self: end;
         align-self: center;
         list-style-type: none;
         margin: 0;
         padding: 0;
         text-align: center;
      }

      li {
         display: inline-block;
         padding: 0 18px;

         a {
            @include transition(all .2s linear);
            color: $light-header-text;
         }
      }
      li.current {
         a {
            @include transition(all .2s linear);
            color: $light-tint-dark;
         }
      }
      li {
         a:hover {
            @include transition(all .2s linear);
            color: $light-tint;
         }
      }
   }
}


@media only all and (max-width: $max-content-width) {
  header {
    label[for=hamburgerCheckbox]:before {
      display: inline-block;
      content: "☰";
      color: black;
      background: $light-tint;
      line-height: 1.2em;
      font-size: 1.2em;
      padding: 10px;
      width: 1.2em;
      height: 1.2em;
      border-radius: 2px;

      position: absolute;
      right: 10px;
      top:10px;
      text-align: center;
    }
    label[for=hamburgerCheckbox] {
      display: block;
      position: absolute;
      right: 10px;
      top: 0px;
    }
    #hamburgerCheckbox:checked ~ label[for=hamburgerCheckbox]:before {
      text-align: center;
      content: "⨉";
    }
    #hamburgerCheckbox ~ ul {
      display:none;
    }
    #hamburgerCheckbox:checked ~ ul {
      background-color: $light-header-background;
      border: 1px solid $light-tint;
      border-radius: 2px;
      position: absolute;
      font-size: 1.2em;
      max-width: 25em;
      margin: auto 0px auto auto;
      left: 20px;
      right: 20px;
      top: calc(1.75em + 30px);
      display: block;
      li {
        display: block;
        border-top: 1px solid $light-grey-medium;
        padding: 0;
        & a {
          display: block;
          padding: 10px;
        }
        &:hover {
           @include transition(all .3s linear);
           background-color: $light-tint;
           a {
              color: $light-text-on-tint;
           }
        }
        &:first-child {
          border-top: 0px solid transparent;
        }
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
.navigate-back-to-top:before {
   background: $dark-tint;
}

header {
   background-color: $dark-header-background;
   nav {
      .logo {
         a {
            color: $dark-tint;
         }
      }

      li {
         a {
            color: $dark-header-text;
         }
      }
      li.current {
         a {
            color: $dark-tint-dark;
         }
      }
      li {
         a:hover {
            color: $dark-tint;
         }
      }
   }
}


@media only all and (max-width: $max-content-width) {
  header {
    label[for=hamburgerCheckbox]:before {
      background: $dark-tint;
    }
    #hamburgerCheckbox:checked ~ ul {
      background-color: $dark-header-background;
      border: 1px solid $dark-tint;
      li {
        border-top: 1px solid $dark-grey-medium;
        &:hover {
           background-color: $dark-tint;
           a {
              color: $dark-text-on-tint;
           }
        }
      }
    }
  }
}
}
