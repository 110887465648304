// Colors
$light-tint: #fed136;
$light-tint-dark: #c66b02;
$light-text: #222;
$light-inverse-text: white;
$light-text-on-tint: black;

$light-background: white;
$light-inverse-background: black;

$light-grey-dark: #666;
$light-grey-medium: #bbb;
$light-grey-bright: #f9f9f9;

$light-header-background: black;
$light-header-text: white;

$dark-tint: #fed136;
$dark-tint-dark: #c66b02;
$dark-text: #ddd;
$dark-inverse-text: white;
$dark-text-on-tint: #333;

$dark-background: #333;
$dark-inverse-background: white;

$dark-grey-dark: #f9f9f9;
$dark-grey-medium: #aaa;
$dark-grey-bright: #444;

$dark-header-background: black;
$dark-header-text: white;

// Sizes
$max-content-width: 900px;

// Fonts
$serif-primary: 'Libre Baskerville', 'Times New Roman', Times, serif;
$serif-secondary: Palatino, 'Palatino LT STD', 'Palatino Linotype', 'Book Antiqua', 'Georgia', serif;
$sans-serif: 'Oswald', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
$monospaced: Menlo, Monaco, monospace;
$fancy: 'Merienda', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
     -moz-box-sizing: $type;
          box-sizing: $type;
}

@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
          transition: $args;
}
