.timeline {
   padding: 3rem 0;

   .grid {
      max-width: $max-content-width;
      margin: 0 auto;
      padding-top: 2rem;
      .station {
         display: grid;
         grid-template-columns: 1fr 80px 6px 80px 1fr;
         grid-template-rows: auto 35px;

         p {
            padding: 0.5em 0 1em 0;
         }

         .description {
            padding-top: 0.5rem;
         }
         &-odd {
            text-align: right;
            h1, h2 {
               text-align: right;
            }
            .description {
               grid-column: 1 / 2;
            }
         }
         &-even {
            h1, h2 {
               text-align: left;
            }
            .description {
               grid-column: 5 / 6;
            }
         }
         .image {
            grid-column: 2 / 5;
            grid-row: 1 / 1;
            justify-self: center;

            img {
               width: 128px;
               height: 128px;
               border-radius: 50%;
               border: 5px solid $light-grey-medium;
            }
         }
         .line {
            grid-column: 3 / 4;
            border: 3px solid $light-grey-medium;
            grid-row: 1 / 3;
         }

         &-outlook {
            display: grid;
            grid-template-columns: 1fr 128px 1fr;

           .circle {
               grid-column: 2 / 3;
               grid-row: 1 / 2;
               width: 128px;
               height: 128px;
               border-radius: 50%;
               border: 5px solid $light-grey-medium;
               background-color: $light-tint;
           }
           .message {
               font-family: $fancy;
               padding: 10px;
               color: $light-text-on-tint;
               grid-column: 2 / 3;
               grid-row: 1 / 2;
               align-self: center;
               text-align: center;
               display: inline-block;
            }
         }
      }
   }
}

@media (max-width: 600px) {
.timeline {
   .grid {
      .station {
         display: grid;
         grid-template-columns: 80px 6px 80px auto;

         .description {
            text-align: left;
            h1, h2 {
               text-align: left;
            }
            grid-column: 4 / 5;
         }
         .image {
            grid-column: 1 / 4;
         }
         .line {
            grid-column: 2 / 3;
         }

         &-outlook {
            grid-template-columns: 19px 128px auto;
         }
      }
   }
}

}

@media (prefers-color-scheme: dark) {
.timeline {
   .grid {
      .station {
         .image {
            img {
               border: 5px solid $dark-grey-medium;
            }
         }
         .line {
            border: 3px solid $dark-grey-medium;
         }

         &-outlook {
           .circle {
               border: 5px solid $dark-grey-medium;
               background-color: $dark-tint;
           }
           .message {
               color: $dark-text-on-tint;
            }
         }
      }
   }
}
}

