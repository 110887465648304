* {
  @include box-sizing;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  border: 0;
}

html,
body {
  color: $light-text;
  background-color: $light-background;

  margin: 0;
  padding: 0;
  overflow: hidden;
}

html {
  font-family: $serif-primary;
  font-size: 14px;
  overflow-y: scroll;

  @media (min-width: 600px) {
    font-size: 16px;
  }

  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
   html {
      scroll-behavior: auto;
   }
}

body {
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $sans-serif;
  font-weight: 400;
  text-align: center;
  line-height: normal;

  color: $light-grey-dark;
}
h2 {
  font-family: $fancy;
  font-weight: bold;
  color: $light-grey-medium;
}

a {
  text-decoration: none;
}

blockquote {
  border-left: .25rem solid $light-grey-medium;
  color: $light-grey-dark;
  margin: .8rem 0;
  padding: .5rem 1rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    padding: 0 5rem 0 1.25rem;
  }
}

img {
  display: block;
  margin: 0 0 1rem;
  max-width: 100%;
}

td {
  vertical-align: top;
}

dt {
   margin: .8rem 0 .4rem 0;
   font-weight: 700;
}

body{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}


@media (prefers-color-scheme: dark) {
/* CSS Code wenn Dark Mode aktiv */ 
html,
body {
  color: $dark-text;
  background-color: $dark-background;
}

h1,
h3,
h4,
h5,
h6 {
  color: $dark-grey-dark;
}
h2 {
  color: $dark-grey-medium;
}

blockquote {
  border-left: .25rem solid $dark-grey-medium;
  color: $dark-grey-dark;
}
}

