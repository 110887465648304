.boxes {
   max-width: $max-content-width;
   margin: 0 auto;
   padding: 3rem 0;

   .grid {
      display: grid;

      margin: 2rem auto;

      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      grid-gap: 40px 20px;

      .box {
         justify-self: center;
         text-align: center;
         width: 300px;

         & > span {
            background-color: $light-tint;
            color: $light-text-on-tint;
            padding: 1rem;
            font-size: 56pt;
            border-radius: 50%;
         }
         & > h1 {
            padding-top: 1rem;
         }
         & > h2 {
            padding-bottom: 1rem;
         }
      }
   }
}

@media (prefers-color-scheme: dark) {
.boxes {
   .grid {
      .box {
         & > span {
            background-color: $dark-tint;
            color: $dark-text-on-tint;
         }
      }
   }
}
}
