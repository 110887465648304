.section {
   &-even {
   }
   &-odd {
      background-color: $light-grey-bright;
   }
}
@media (prefers-color-scheme: dark) {
   .section {
      &-odd {
         background-color: $dark-grey-bright;
      }
   }
}
