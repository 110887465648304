.text {
   padding: 3rem 1rem;
   text-align: center;
   .content {
      text-align: left;
      max-width: $max-content-width;
      margin: 2rem auto;

      p {
         padding: 0.5em 0 1em 0;
      }
   }
}
