.text-imprint {
   a {
      @include transition(all .2s linear);
      color: $light-tint;
   }
   a:hover {
      @include transition(all .2s linear);
      color: $light-tint;  /* TODO */
   }
}
@media (prefers-color-scheme: dark) {
.text-imprint {
   a {
      color: $dark-tint;
   }
   a:hover {
      @include transition(all .2s linear);
      color: $dark-tint;  /* TODO */
   }
}
}
