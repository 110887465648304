.banner {
   background-color: $light-grey-medium;
   background-size: cover;
   background-position: center center;

   display: grid;
   width: 100%;
   min-height: 80vh;
   grid-template-columns: 100%;
   grid-template-rows: auto auto;

   h1 {
      font-size: 50px;
      align-self: end;
      padding-bottom: 1em;
   }
   h2 {
      font-size: 75px;
      align-self: start;
   }
   h1, h2 {
      color: $light-tint;
      text-shadow: 1px 1px 3px $light-text;
      justify-self: center;
      max-width: 80%;
   }
}
