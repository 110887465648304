footer {
   z-index: 1000;
   width: 100%;
   background-color: $light-inverse-background;
   color: $light-inverse-text;

   padding: 10px 20px;
   font-family: $sans-serif;
   font-weight: 300;
   font-style: normal;
   font-size: 18px;
   margin-top: auto;

   display: grid;
   grid-template-columns: auto auto;
   grid-auto-flow: column;

   .imprint {
      justify-self: end;
   }

   a {
      @include transition(all .2s linear);
      color: $light-inverse-text;
   }
   a:hover {
      @include transition(all .2s linear);
      color: $light-tint;
   }
}

