@import 'parts/fonts';
@import 'parts/variables';
@import 'parts/base';
@import 'parts/header';
@import 'parts/footer';
@import 'parts/section';
@import 'parts/banner';
@import 'parts/boxes';
@import 'parts/text';
@import 'parts/timeline';
@import 'parts/imprint';
